






























































import { mapGetters } from 'vuex';
import Vue from 'vue';
import Component from 'vue-class-component';
import CommonFormElement, { SelectOption } from 'common-modules/src/components/CommonFormElement.vue';
import CommonProfileImage from 'common-modules/src/components/CommonProfileImage.vue';
import JwlSubmitRow from '@/components/JwlSubmitRow.vue';
import JwlButton from '@/components/JwlButton.vue';
import BackBar from '@/components/BackBar.vue';
import { User } from '@/store/interfaces/User';

const CommonError = () => import('common-modules/src/components/CommonError.vue');

@Component({
  components: {
    BackBar,
    CommonFormElement,
    JwlButton,
    CommonError,
    CommonProfileImage,
    JwlSubmitRow,
  },
  computed: mapGetters([
    'currentUser',
  ]),
})
export default class JwlUserEdit extends Vue {
  $refs!: {
    submitForm: HTMLFormElement;
    enabledFormField: any;
  }

  user: User | null = null;
  fetching = true;
  error = null;
  formHasErrors = false;
  formSubmitStatus = 0;
  possibleRoles = [];
  learningGroups: SelectOption[] = [];
  subentities = {
    student: false,
    facilitator: false,
  };

  fetchData (): void {
    this.$store.dispatch('getData', `user/detail/${this.$route.params.user}`)
      .then((data) => {
        this.possibleRoles = data.includes.roles;
        this.subentities = data.subentities;
        this.learningGroups = data.includes.learningGroups;
        this.user = data.user;
        this.fetching = false;
      })
      .catch((e) => {
        this.error = e;
      });
  }

  fetchRoles (): void {
    this.$store.dispatch('getData', 'user/detail/new')
      .then((data) => {
        this.possibleRoles = data;
        this.fetching = false;
      })
      .catch((e) => {
        this.error = e;
      });
  }

  submitForm (): void {
    const { submitForm } = this.$refs;
    if (submitForm.reportValidity() && this.formSubmitStatus !== 1) {
      this.formHasErrors = false;
      this.formSubmitStatus = 1;

      const formData = new FormData(submitForm);
      if (!formData.has('enabled')) {
        formData.set('enabled', this.$refs.enabledFormField.onFormValidation().value);
      }

      this.$store.dispatch('postData', {
        url: `user/detail/${this.$route.params.user}`,
        formData,
      }).then((data) => {
        this.user = data.user;
        this.formSubmitStatus = 2;

        setTimeout(() => {
          this.formSubmitStatus = 0;
        }, 2000);
      }).catch(() => {
        this.formSubmitStatus = -1;
      });
    } else if (this.formSubmitStatus !== 1) {
      this.formHasErrors = true;
      this.formSubmitStatus = 3;
    }
  }

  makeStudent (): void {
    this.subentities.student = true;
  }

  get name (): string {
    if (this.user) {
      const names = [
        this.user.firstName,
        this.user.middleName,
        this.user.lastName,
      ];
      return names.join(' ');
    }

    return 'Unknown';
  }

  created (): void {
    if (this.$route.name === 'userEdit') {
      this.fetchData();
    } else {
      this.fetchRoles();
    }
  }
}
